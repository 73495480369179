<template>
    <div>
        <v-toolbar elevation="1" dark color="primary">
                <v-toolbar-title>
                    Tiempos de espera CONSULTA EXTERNA <v-spacer/><div v-if="fechaDe">{{fechaDe}} A {{fechaA}}</div>
                </v-toolbar-title>
        </v-toolbar>
        <v-sheet>
            <v-row no-gutters>
                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                    <v-sheet elevation="1" class="mt-1">
                        <v-date-picker
                            class="mb-2"
                            v-model="fechas"
                            range
                            :max="estaFecha"
                            full-width
                            no-title
                            locale="es-mx"
                            scrollable
                        ></v-date-picker>
                    </v-sheet>
                    <v-btn
                        :disabled="!fechaDe"
                        :loading="loadingAtenciones"
                        @click="cargaAtencionesBtn"
                    >
                        Buscar
                    </v-btn>
                </v-col>
                <v-col class="px-1" xs="12" sm="12" md="9" lg="9" xl="9" cols="12">
                        <v-data-table
                        elevation="2"
                        :headers="headersAtn"
                        :items="atenciones"
                        disable-pagination
                        disable-filtering
                        disable-sort
                        hide-default-footer
                        :loading="loadingAtenciones"
                    >

                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
export default {
    data: () => ({

        fechas:[],
        fechaArr:[],
        loadingAtenciones:false,
        atenciones:[],
        headersAtn:[
            {text:'Paciente', value:'paciente'},
            {text:'Sexo', value:'sexo', align:'center', width:'5%'},
            {text:'Especialidad', value:'especialidad', align:'center'},
            {text:'Recibe', value:'recibe', align:'center'},
            {text:'Atiende', value:'atiende', align:'center'},
            {text:'Espera (minutos)', value:'diferencia', align:'center', width:'10%'},
        ],


    }),
    methods:{

        async cargaAtenciones(){
            this.loadingAtenciones = true
            try{
                let atn = await this.$http({
                    url:'/reportes/reporteEsperaConsulta',
                    methods:'GET',
                    params:this.paramsAtn
                })
                this.loadingAtenciones = false
                if (atn.data.estado){
                    this.atenciones = atn.data.root
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cargar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingAtenciones = false
                this.$store.dispatch('security/revisaError',err)
            }

        },
        cargaAtencionesBtn(){
            this.paramsAtn = {
                medico: this.medico,
                especialidad:this.especialidad,
                fechaDe: this.fechaDe,
                fechaA: this.fechaA,
            }
            this.cargaAtenciones()
        },
        getFecha(fc){
            if (fc){
                return this.moment(fc).format('DD/MM/YYYY')
            } else {
                return('')
            }
        },

    },

    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },
        fechaDe(){
            return (this.getFecha(this.fechaArr[0]))
        },
        fechaA(){
            return (this.getFecha(this.fechaArr[1]))
        },
    },
    watch:{
        fechas(){
            let far=[]
            this.fechaArr = []
            if(this.fechas.length > 0){
                this.fechas.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.fechaArr.push(new Date(Math.min.apply(null,far)))
                this.fechaArr.push(new Date(Math.max.apply(null,far)))
            }
        },
    },
}
</script>

<style>

</style>